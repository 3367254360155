.main-screen {
  width: 100%;
  height: 90vh;
  background: #3c4043;
  position: relative;
}
.main-screen .card {
  background: #3c4043;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.wrapper {
  width: 100%;
}

.sendmessage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendmessage input {
  width: 90%;
  border-radius: 10px;
  padding: 5px 10px;
}
.chat-container {
  height: 700px;
  overflow-y: scroll;
}

.chat-user {
  height: 30px;
  min-width: 30px;
  background-color: green;
  color: white;
  border-radius: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  height: 10vh;
}

.msg-send {
  background-color: #005063;
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  word-break: break-all;
}

.msg-recieve {
  background-color: #0083a3;
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  word-break: break-all;
}

.chat-disable {
  display: none;
}

.chat {
  position: absolute;
  right: 0;
  height: 100%;
  width: 300px;
  z-index: 30;
  top: 0;
  bottom: 0;
  background-color: #fff;
  padding: 11px;
  overflow: hidden;
}
.meeting-footer {
  background: #202124;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting-icons {
  color: white;
  border-radius: 50%;
  background: #3c4043;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  background-color: #ea4335;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.participants {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 20px;
  height: 85vh;
  padding: 10px;
}

.participants img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

@media (max-width: 800px) {
  .participants {
    grid-template-columns: repeat(var(--grid-col-size), 1fr);
    grid-template-rows: repeat(var(--grid-row-size), 1fr);
  }
}

.video {
  background-color: #353b48;
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  border-radius: 5px;
}

.participant {
  background-color: #353b48;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  min-height: 200px;
}

.muted {
  color: #fff;
  right: 10px;
  position: absolute;
  top: 10px;
  background: #353b48;
  border-radius: 50%;
  height: 22px;
  width: 22px !important;
}

.hide {
  display: none;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  font-size: 150px;
  font-family: roboto, arial;
  color: white;
  text-align: center;
  background: orange;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.name {
  color: white;
  bottom: 10px;
  position: absolute;
  left: 20px;
}

@media (max-width: 800px) {
  .participant {
    min-height: 0px;
  }

  .avatar {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}
